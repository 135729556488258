import { useState, useEffect, useContext } from 'react';
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import { Box, Stepper, Button, CircularProgress, LinearProgress } from '@mui/material';

import Question from '../Components/Questions';
import ProgressBar from '../Components/ProgressBar';
import ProgressBarSingle from '../Components/ProgressBarSingle';

import prevArrow from '../Assets/Images/prev-arrow.svg';
import nextArrow from '../Assets/Images/next-arrow.svg';

import '../App.scss';

import AssessmentQuestionContext from '../Store/AssessmentQuestionContext';
import useWindowDimensions from '../Hooks/useWindowDimensions';

const Assessment = () => {

  const { windowWidth } = useWindowDimensions();
  const assessCtx     = useContext(AssessmentQuestionContext);
  let navigate        = useNavigate();
  const { id }        = useParams();

  useEffect(() => {
    // document.body.style.backgroundColor = "#E5E5E5";
    
    setIsLoading(true);
    // setQs(questions);
    if(id != null) {      
      getAssessment();
    } else {
      getQuestions();
    }
    
  }, []);
  
  // localStorage.setItem("backFromDetailsPage", false);
  // console.log(localStorage.getItem("backFromDetailsPage"));

  const [searchParams, setSearchParams]     = useSearchParams();
  const [isLoading, setIsLoading]           = useState(true);
  const [activeStep, setActiveStep]         = useState(0);
  const [prevValue, setPrevValue]           = useState(0);
  const [nextValue, setNextValue]           = useState(0);
  const [sections, setSections]             = useState([]);
  const [questions, setQuestions]           = useState([]);
  const [questionCount, setQuestionCount]   = useState(0);
  const [currentHash, setCurrentHash]       = useState(0);
  const [hasAdditional, setHasAdditional]   = useState(false);
  const [qs, setQs]                         = useState([]);
  const [nextBtnText, setNextBtnText] = useState("NEXT");
  const [prevBtnText, setPrevBtnText] = useState("PREV");
  // const [nextDisabled, setNextDisabled] = useState(true);

  // console.log("searchParams.get('member')", searchParams.get("member"));  


  
  const backFromFirst = localStorage.getItem("backFromFirst");
  const goToNextSectionFirst = localStorage.getItem("goToNextSectionFirst");
  const backFromSecond = localStorage.getItem("backFromSecond");
  const goToNextSectionSecond = localStorage.getItem("goToNextSectionSecond");
  const backFromThird = localStorage.getItem("backFromThird");
  const goToNextSectionThird = localStorage.getItem("goToNextSectionThird");
  const backFromFirstFunc = () => {
    if(backFromFirst === "true") {
      setActiveStep(2);
      localStorage.removeItem("backFromFirst");
    }
  }
  const goToNextSectionFirstFunc = () => {
    if(goToNextSectionFirst === "true") {
      setActiveStep(3);
      localStorage.removeItem("goToNextSectionFirst");
    }
  }
  
  useEffect(() => {
    backFromFirstFunc();
  }, [backFromFirst])
  
  useEffect(() => {
    goToNextSectionFirstFunc();
  }, [goToNextSectionFirst])

  useEffect(() => {
    if(windowWidth < 850) 
    {setNextBtnText("");setPrevBtnText("");}
    else 
    {setNextBtnText("NEXT");setPrevBtnText("PREV");}
  
  }, [windowWidth])
  

  const backFromSecondFunc = () => {
    if(backFromSecond === "true") {
      setActiveStep(5);
      localStorage.removeItem("backFromSecond");
    }
  }
  const goToNextSectionSecondFunc = () => {
    if(goToNextSectionSecond === "true") {
      setActiveStep(6);
      localStorage.removeItem("goToNextSectionSecond");
    }
  }
  
  useEffect(() => {
    backFromSecondFunc();
  }, [backFromSecond])
  
  useEffect(() => {
    goToNextSectionSecondFunc();
  }, [goToNextSectionSecond])

  const backFromThirdFunc = () => {
    if(backFromThird === "true") {
      setActiveStep(9);
      localStorage.removeItem("backFromThird");
    }
  }
  const goToNextSectionThirdFunc = () => {
    if(goToNextSectionThird === "true") {
      setActiveStep(10);
      localStorage.removeItem("goToNextSectionThird");
    }
  }
  
  useEffect(() => {
    backFromThirdFunc();
  }, [backFromThird])
  
  useEffect(() => {
    goToNextSectionThirdFunc();
  }, [goToNextSectionThird])





  function getAssessment() {
    fetch(process.env.REACT_APP_API_URI + '/v1/assessment/' + id + '/review', {
			method: 'GET',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json' }
		}).then(response => {
			return response.json();
		}).then(data => {
			if(data.status == "success") {
        if(data.sections) {
          assessCtx.setSections(data.sections);
          localStorage.setItem("sections", JSON.stringify(data.sections));
        }
        assessCtx.addQuestions(data.questions);
				setSections(data.sections);
				setQuestions(data.questions);
        setQuestionCount(data.questions.length);
				setHasAdditional(data.hasAdditional);
        setIsLoading(false);
        setActiveStep(data.questions.length - 1);
        setPrevValue(data.questions[data.questions.length - 1].answer);
        setCurrentHash(id);
			} else {
				// setOpenSnackbar(true);
			}
		});
  }

  function getQuestions() {

    if(assessCtx.questions.length > 0) {
      console.log("assessCtx.questions", assessCtx.questions);
      setQuestions(assessCtx.questions);
      setQuestionCount(assessCtx.questions.length);
      setIsLoading(false);

      // go to the last additional question if coming back from Details page 
      // typeof localStorage.getItem("backFromDetailsPage") is string so "true"
      if(localStorage.getItem("backFromDetailsPage") === "true") {
        setActiveStep(assessCtx.questions.length - 1);
      } else {
        // go to the first unanswered question 
        for (let i = 0; i < assessCtx.questions?.length; i++) {
          if(!assessCtx.questions?.[i]?.answer && !assessCtx.questions?.[i]?.answerTxt) {
            setActiveStep(i);
            break;
          } else setActiveStep(assessCtx.questions.length - 1);
        }
      }

    } else {
      let clientId              = process.env.REACT_APP_CLIENT_ID;
      
      fetch(process.env.REACT_APP_API_URI + '/v1/questions/' + clientId, {
        method: 'GET',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' }
      }).then(response => {
        return response.json();
      }).then(data => {
        if(data.status == "success") {
          let questions = data.questions;
          if(retrieveStoredAnswers()) {
            let answeredQuestions = [];
            let storedQuestions = retrieveStoredAnswers();
            questions.forEach(q => {
              let question = storedQuestions.find(obj => obj.id == q.id);
              if(question) {
                q.answerTxt = question.answerTxt;
                q.answer = question.answer;
                q.answers = question.answers;
              }
            })
          }
          console.log(data);
          assessCtx.setSections(data.sections);
          localStorage.setItem("sections", JSON.stringify(data.sections));
          setSections(data.sections);
          setQuestions(data.questions);
          setQuestionCount(data.questions.length);
          setHasAdditional(data.hasAdditional);
          assessCtx.addQuestions(questions);
          setIsLoading(false);
        } else {
          // setOpenSnackbar(true);
        }
      });
    }

  }

  console.log("hasAdditional", hasAdditional);

  function completeAssessment() {

    setIsLoading(true);

    let apicall               = '/v1/assessment';
    if(searchParams.get("leader") != null) {
      apicall                 = '/v1/assessment/' + searchParams.get("leader") + '/member/' + searchParams.get("user");
    }

    let isReview              = false;
    if(id != null) {      
      isReview                = true;
    }

    let clientId              = process.env.REACT_APP_CLIENT_ID;

		fetch(process.env.REACT_APP_API_URI + apicall, {
			method: 'POST',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
        clientId,
				questions,
        isReview,
        id
			})
		}).then(response => {
			return response.json();
		}).then(data => {
			if(data.status == "success") {
				// if(data.hasAdditional == "yes" || hasAdditional) {
        //   if(data.member == true) {
        //     // if(searchParams.get("member") === data.memberId) {
        //     //   navigate("/assessment-additional/" + data.id + "?" + searchParams, { replace: true });  
        //     // } else navigate("/assessment-additional/" + data.id + "?member=" + data.memberId + '&' + searchParams, { replace: true });
        //     if(searchParams.get("member") === data.memberId) {
        //       navigate("/assessment-additional/" + data.id + "?" + searchParams, { replace: true });  
        //       // navigate("/details/" + data.id + "?" + searchParams, { replace: true });  
        //     // } else navigate("/assessment-additional/" + data.id + "?member=" + data.memberId + '&' + searchParams, { replace: true });
        //     // } else navigate("/details/" + data.id + "?member=" + data.memberId + '&' + searchParams, { replace: true });
        //     } else navigate("/assessment-additional/" + data.id + "?member=" + data.memberId + '&' + searchParams, { replace: true });
        //   // } else navigate("/details/" + data.id + '?' + searchParams, { replace: true });
        //   } else navigate("/assessment-additional/" + data.id + "?" + searchParams, { replace: true });
        // } else {
          if(data.member == true) {
            if(searchParams.get("member") === data.memberId) {
              navigate("/details/" + data.id + "?" + searchParams, { replace: true });
            } else navigate("/details/" + data.id + "?member=" + data.memberId + '&' + searchParams, { replace: true });
          } else navigate("/details/" + data.id + '?' + searchParams, { replace: true });
        // }
			} else {
				// setOpenSnackbar(true);
			}
		});

	}

  const retrieveStoredAnswers = () => {
    if (typeof window !== 'undefined') {
      // Get the current localstorage
      let savedAnswers = JSON.parse(localStorage.getItem('assessmentAnswers'));
      if(savedAnswers && savedAnswers.length != 0) {
        return savedAnswers;
      }
    }
    return;
  }

  const prevQuestion = () => {

    if(activeStep < 1) {
      navigate("/start?" + searchParams, { replace: true });
    } 
    // else if(activeStep === 3) {
    //   setActiveStep(2);
    //   if(currentHash) navigate("/progress-one/" + currentHash + '/?' + searchParams, { replace: true });
    //   else navigate("/progress-one?" + searchParams, { replace: true });
    // } 
    // else if(activeStep === 6){
    //   setActiveStep(5);
    //   if(currentHash) navigate("/progress-two/" + currentHash + '/?' + searchParams, { replace: true });
    //   else navigate("/progress-two?" + searchParams, { replace: true });
    // } 
    // else if(activeStep === 10){
    //   setActiveStep(9);
    //   if(currentHash) navigate("/progress-three/" + currentHash + '/?' + searchParams, { replace: true });
    //   else navigate("/progress-three?" + searchParams, { replace: true });
    // }
     else {
      setActiveStep(activeStep - 1);
      setPrevValue(questions[activeStep - 1].answer);
    }
    // if(activeStep < 1) {
    //   if([...searchParams]?.length) navigate("/before-you-start?" + searchParams, { replace: true });
    //   else navigate("/before-you-start", { replace: true });
    // } else {
    //   setActiveStep(activeStep - 1);
    //   setPrevValue(questions[activeStep - 1].answer);
    // }
  };

  console.log("searchParams", [...searchParams]);

  const nextQuestion = () => {
    assessCtx.updateAnswer(questions[activeStep].id, questions[activeStep].answer);
    assessCtx.updateTextAnswer(questions[activeStep].id, questions[activeStep].answerTxt);
    if (activeStep === questions?.length - 1) {
      setTimeout(() => { completeAssessment(); }, 500);

    }

    // else if(activeStep === 2) {
    //   // console.log("here");
    //   setActiveStep(3);
    //   if(currentHash) navigate("/progress-one/" + currentHash + '/?' + searchParams, { replace: true });
    //   else navigate("/progress-one?" + searchParams, { replace: true });
    // } 
    // else if(activeStep === 5){
    //   setActiveStep(6);
    //   if(currentHash) navigate("/progress-two/" + currentHash + '/?' + searchParams, { replace: true });
    //   else navigate("/progress-two?" + searchParams, { replace: true });

    // } 
    // else if(activeStep === 9){
    //   setActiveStep(10);
    //   if(currentHash) navigate("/progress-three/" + currentHash + '/?' + searchParams, { replace: true });
    //   else navigate("/progress-three?" + searchParams, { replace: true });
    // }

    else if((activeStep < questions.length - 1)) {
      // assessCtx.updateAnswer(questions[activeStep].id, questions[activeStep].answer);
      // assessCtx.updateTextAnswer(questions[activeStep].id, questions[activeStep].answerTxt);
      setTimeout(() => {
        setActiveStep(activeStep + 1);
        setPrevValue(questions[activeStep + 1].answer);
      }, 20);
    } 
    // else if(currentHash != 0 && hasAdditional) {
    //   if(searchParams.get("member") != null) {
    //     navigate("/assessment-additional/" + currentHash + '?member=' + searchParams.get("member") + '&' + searchParams, { replace: true });
    //   } else navigate("/assessment-additional/" + currentHash + '?' + searchParams, { replace: true });
    // } else {
    //   if(searchParams.get("member") != null) {
    //     navigate("/details/" + currentHash + '?member=' + searchParams.get("member"), { replace: true });
    //   } else navigate("/details/" + currentHash, { replace: true });
    // }

    else if(currentHash != 0 && hasAdditional) {
      if(searchParams.get("member")) {
        // navigate("/assessment-additional/" + currentHash + "?" + searchParams, { replace: true });
        navigate("/details/" + currentHash + "?" + searchParams, { replace: true });
      } else {
        // go to /pre-additional-info if user is leader. only referrals will have leader param in their link 
        // if(!searchParams.get("leader")) navigate("/assessment-additional/" + currentHash + '?' + searchParams, { replace: true });
        if(!searchParams.get("leader")) navigate("/details/" + currentHash + '?' + searchParams, { replace: true });
      }
    }
  };

  const moveToNextQuestion = (answer) => {
    if(activeStep < questions.length - 1) {
      questions[activeStep].answer = answer;
      assessCtx.updateAnswer(questions[activeStep].id, questions[activeStep].answer);
      assessCtx.updateTextAnswer(questions[activeStep].id, questions[activeStep].answerTxt);
      setTimeout(() => {
        setActiveStep(activeStep + 1);
        setPrevValue(questions[activeStep]?.answer);
       }, 500);
      
      //  setTimeout(() => {
      //   if(activeStep == 2) {
      //     if(currentHash) navigate("/progress-one/" + currentHash + '/?' + searchParams, { replace: true });
      //     else navigate("/progress-one?" + searchParams, { replace: true });
      //   }
      //   else if(activeStep == 5) {
      //     if(currentHash) navigate("/progress-two/" + currentHash + '/?' + searchParams, { replace: true });
      //     else navigate("/progress-two?" + searchParams, { replace: true });
      //   }
      //   else if(activeStep == 9) {
      //     if(currentHash) navigate("/progress-three/" + currentHash + '/?' + searchParams, { replace: true });
      //     else navigate("/progress-three?" + searchParams, { replace: true });
      //   }
      // }, 500);

      console.log(activeStep)
    } else {
      questions[activeStep].answer = answer;
      setTimeout(() => { completeAssessment(); }, 500);
    }
  }

  // const nextDisabled = () => {
  //   if(questions[activeStep].answer > 0) {
  //     return {display: "block"};
  //   };
  //   return {display: "none"};
  // }
  // const nextDisabled = (answer) => {
  //   if(questions[activeStep].mandatory == "no") {
  //     return true;
  //   } else {
  //     if(questions[activeStep].type == "slider" && questions[activeStep].answer != 0) {
  //       return true;
  //     } else if(questions[activeStep].type == "radio" && questions[activeStep].answer != 0) {
  //       return true;
  //     } else if(questions[activeStep].type == "textarea" && questions[activeStep].answerTxt != "") {
  //       return true;
  //     }
  //   } 
  //   return false;
  // }

  const nextDisabled = () => {
    if(questions?.[activeStep]?.answerType === "textarea" && questions?.[activeStep]?.answerTxt?.length > 0) {
      return { display: "flex" };
    }
    if(questions?.[activeStep]?.answer > 0) {
      return { display: "flex" };
    };
    return { display: "none" };
  }

  const getQuestionsPerSection = () => {
    let arr = questions?.map(q => q.sectionId);
    let count = {};
    arr.forEach(element => {
      count[element] = (count[element] || 0) + 1;
    });
    return Object.values(count);
  }
  
  const questionsPerSection = getQuestionsPerSection();
  // console.log("questionsPerSection", questionsPerSection);

  const currentSectionName = assessCtx.sections?.find(section => section.id === questions[activeStep]?.sectionId)?.section;

  const getStepContent = (step) => {
    return (
      <Question question={questions[step]} questionCount={questionCount} questionAnswered={moveToNextQuestion} currentValue={prevValue}/>
    );
  };

  if (isLoading) {
		return <div className="container assessment loading"><CircularProgress style={{ color: "#007670" }} size={60} /></div>
	}

  return (
    <>
    {windowWidth < 831 && (
      <div style={{ margin: "30px auto", width: "85%" }}>
        <ProgressBar currentProgress={activeStep} questionsPerSection={questionsPerSection} sections={assessCtx.sections} count={questionCount} currentSectionName={currentSectionName} />
      </div>
      )}
    <div className="container assessment">
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep}></Stepper>
      </Box>
      <div>
        <div className="steps-container">
          <div>
            <div className="steps">

              <div className="spacer" style={{ height: windowWidth > 650 ? "30px" : "30px" }}></div>

              {windowWidth > 830 && <ProgressBar currentProgress={activeStep} questionsPerSection={questionsPerSection} sections={assessCtx.sections} count={questionCount} currentSectionName={currentSectionName} />}
              {/* <ProgressBarSingle currentProgress={activeStep} totalNumOfQuestions={questionCount} /> */}
              {/* <div className="spacer"></div>
              <div className="spacer"></div>
              <div className="spacer mobile-spacer"></div> */}
              <div className="spacer mobile-spacer" style={{ height: windowWidth > 650 ? "0px" : "30px" }}></div>
              
              <div className="progress-indicator"><strong>{activeStep + 1}</strong>/{questions.length}</div>

              {getStepContent(activeStep)}

              <div className="step-buttons">
                <Button className="prev-btn" onClick={prevQuestion}>
                  <img src={prevArrow} />&nbsp;&nbsp;{prevBtnText}
                </Button>
                <Button style={nextDisabled()} className={`next-btn`} onClick={nextQuestion} disabled={questions[activeStep]?.answer > 0 ? false : true} >
                  {nextBtnText}&nbsp;&nbsp;<img className='next-arrow' src={nextArrow} />
                </Button>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </>
  );
}
export default Assessment;
