import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link, useParams, useNavigate } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";

import prevArrow from "../Assets/Images/prev-arrow.svg";
import nextArrow from "../Assets/Images/next-arrow.svg";

import testimonialImage from "../Assets/Images/testimonial.jpg";
import scheduledImage from "../Assets/Images/schedule-image.png";
import quotationImage from "../Assets/Images/quotation.svg";

import analyzeIcon from '../Assets/Images/insights.svg';
import alignmentIcon from '../Assets/Images/align.svg';
import advanceIcon from '../Assets/Images/advance.svg';
import useWindowDimensions from '../Hooks/useWindowDimensions';
import "../App.scss";
import Testimonial from "../Components/Testimonial";

const Results = (props) => {
  let navigate                    = useNavigate();
  const { id }                    = useParams();
  
  const { windowWidth }           = useWindowDimensions();
  const [isLoading, setIsLoading] = useState(true);
  const [isMember, setIsMember]   = useState(false);
  const [name, setName]           = useState("");
  const [score, setScore]         = useState(0);
  const [subScore1, setSubScore1] = useState(0);
  const [subScore2, setSubScore2] = useState(0);
  const [subScore3, setSubScore3] = useState(0);
  const [subScore4, setSubScore4] = useState(0);
  const [subScore5, setSubScore5] = useState(0);
  const [subScores, setSubScores] = useState([0, 0, 0, 0]);

	
	useEffect(() => { 
		document.body.style.backgroundColor = "#007670";
	},[]);

  useEffect(() => {
    fetchResults();
  }, []);

  // to animate subScores on results page 
  useEffect(() => {
    const timer = setTimeout(() => {
      setSubScores([subScore1, subScore2, subScore3, subScore4, subScore5]);
    }, 250);
    return () => {
      clearTimeout(timer);
    };
  }, [subScore1, subScore2, subScore3, subScore4, subScore5])

  const fetchResults = () => {
    fetch(process.env.REACT_APP_API_URI + "/v1/assessment/" + id, {
      method: "GET",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status === "success") {
          console.log(data);
          setIsLoading(false);
          setName(data.name);
          setScore(data.score);
          setSubScore1(data.subScore1);
          setSubScore2(data.subScore2);
          setSubScore3(data.subScore3);
          setSubScore4(data.subScore4);
          setSubScore5(data.subScore5);
          setIsMember(data.isTeamMember);
        } else {
          // setOpenSnackbar(true);
        }
      });
  };

  const showTitleText = () => {
    if (score > 80) {
      return <h1><span className="user-name">{name}</span>, it looks like you're doing a great job at stewardship and giving. Way to go!</h1>
    } else if (score > 50 && score <= 80) {
      return <h1><span className="user-name">{name}</span>, for churches like yours that are stable, it is easier to move to increased financial giving from this core foundation.</h1>
    } else if (score >= 0 && score <= 50) {
      return <h1><span className="user-name">{name}</span>, it looks like you might be facing challenges in your giving.</h1>
    }
  };
  // const showTitleText = () => {
  //   if (score > 80) {
  //     return <h1><span className="user-name">{name}</span>,  it looks like you’re doing a great job at stewardship and giving. Way to go!</h1>
  //   } else if (score > 60 && score <= 80) {
  //     return <h1><span className="user-name">{name}</span>, it looks like your leadership team is well aligned.</h1>
  //   } else if (score > 40 && score <= 60) {
  //     return <h1><span className="user-name">{name}</span>, for churches like yours that are stable, it is easier to move to increased financial giving from this core foundation.</h1>
  //   } else if (score > 20 && score <= 40) {
  //     return <h1><span className="user-name">{name}</span>, it looks like your school leadership might be poorly aligned.</h1>
  //   } else if (score >= 0 && score <= 20) {
  //     return <h1><span className="user-name">{name}</span>, it looks like you might be facing challenges in your giving.</h1>
  //   }
  // };

  const showScoreText = () => {
    if (score > 80) {
      return <div className="result-text">
        <p>Congratulations! Based on your score, it looks like you're doing a great job at growing giving.</p>
        <p>Because you have momentum and apparent health now, it is more likely that you can increase giving even more as people are engaged and grow in stewardship. What if you could increase your budget by 10%? 25%? Your current momentum gives you more options to expand financial resources for your mission and ministry. How might you build upon this strong foundation?</p>
        
      </div>
    } else if (score > 50 && score <= 80) {
      return <div className="result-text">
        <p>Based on your score, it looks like there's a strong potential to grow your giving more than you might think.</p>
        <p>While this can seem like a challenge initially, focusing on the 5 assessment areas can help you cast a clear vision for generosity and strengthen your giving strategy as you work toward building a culture of biblical stewardship in your church.</p>
      </div>
    } else if (score >= 0 && score <= 50) {
      return <div className="result-text">
        <p>Based on your score, it looks like you might be facing headwinds in your giving, perhaps on a downward trend or missing connection with givers who might want to give more.</p>
        <p>Most often, these areas can be changed with focused attention with increased giving as a result. Most churches can increase financial giving.</p>
        <p>By focusing on the 5 critical areas of this assessment, you can begin to strengthen your approach to biblical stewardship and align around a plan to cultivate a culture of generosity in your church. Let's explore, {name}, how your giving can increase. </p>
      </div>
    }
  };
  // const showScoreText = () => {
  //   if (score > 80) {
  //     return <div className="result-text">
  //       <p>It looks like you currently have some areas of healthy alignment!</p>
  //       <p>An aligned partnership is necessary to accomplish your vision and it's one of several critical success factors in Campaign readiness.</p>
  //       <p>Intentionality in your approach to board development and skills training enables fundraising success.</p>
  //       <p>At Generis, we're experienced in guiding leaders and boards in these areas and are eager to equip you in your journey. We'd be honored to support your growth. Your school has great days ahead!</p>
        
  //     </div>
  //   } else if (score > 60 && score <= 80) {
  //     return <div className="result-text">
  //       <p>It looks like you currently have some areas of healthy alignment!</p>
  //       <p>An aligned partnership is necessary to accomplish your vision and it's one of several critical success factors in Campaign readiness.</p>
  //       <p>Intentionality in your approach to board development and skills training enables fundraising success.</p>
  //       <p>At Generis, we're experienced in guiding leaders and boards in these areas and are eager to equip you in your journey. We'd be honored to support your growth. Your school has great days ahead!</p>

  //     </div>
  //   } else if (score > 40 && score <= 60) {
  //     return <div className="result-text">
  //       <p>Based on your score, it looks like you have some work ahead to achieve stronger alignment.</p>
  //       <p>You may have some areas of alignment, but strategically, it's wise to focus on your areas of concern or your fundraising efforts will flag.</p>
  //       <p>Committing to addressing them is a powerful first step to achieving your vision of a flourishing school.</p>
  //       <p>Watch your email for resources and ways that Generis can help guide you along that path. You and your school have great days ahead!</p>
  //     </div>
  //   } else if (score > 20 && score <= 40) {
  //     return <div className="result-text">
  //       <p>Based on your score, it looks like you might need to address some misalignment among your Board + Head of School leadership team. But, don't be discouraged!</p>
  //       <p>Your journey to more effective alignment will be accelerated by engaging in fruitful discussions about this critical partnership and coming to a common understanding about strategy, engagement, mission, and vision.</p>
  //       <p>Watch your email for resources and ways that Generis can help guide you along that path. You and your school have great days ahead!</p>
  //     </div>
  //   } else if (score >= 0 && score <= 20) {
  //     return <div className="result-text">
  //       <p>Based on your score, it looks like you might need to address some misalignment among your Board + Head of School leadership team. But, don't be discouraged!</p>
  //       <p>Your journey to more effective alignment will be accelerated by engaging in fruitful discussions about this critical partnership and coming to a common understanding about strategy, engagement, mission, and vision.</p>
  //       <p>Watch your email for resources and ways that Generis can help guide you along that path. You and your school have great days ahead!</p>
  //     </div>
  //   }
  // };

  const showProgressBarText = (scoreValue) => {
    if (scoreValue >= 95) {
      return "Enjoying health";
    } else if (scoreValue >= 80 && scoreValue < 95) {
      return "Improving health";
    } else if (scoreValue >= 70 && scoreValue < 80) {
      return "Pursuing health";
    } else if (scoreValue < 70) {
      return "Battling unhealthiness";
    }
  }

  // const showInvite = () => {
  //   if (isMember) {
  //     return null;
  //   } else {
  //     return <div className="invite-container">

  //       <h3>Step Two: Invite your Board Members to also take this assessment.</h3>
  //       <p>When your Board Members take this assessment individually, their results will populate your dashboard as the Head, equipping you with the powerful insight that you've been missing about your team's collective alignment.</p>

  //       {/* <div className="invite-reasons">

  //         <div className="reason">
  //           <img src={analyzeIcon} alt="analyze" /><br />
  //           <div className="height30"></div>
  //           <span>Analyze</span><br />
  //           Discover what level of health your church is currently at
  //         </div>

  //         <div className="reason">
  //           <img src={alignmentIcon} alt="align" /><br />
  //           <div className="height30"></div>
  //           <span>Align</span><br />
  //           Align around a plan for increased effectiveness as a team
  //         </div>

  //         <div className="reason">
  //           <img src={advanceIcon} alt="advance" /><br />
  //           <div className="height30"></div>
  //           <span>Advance</span><br />
  //           Chart your course to move toward a greater level of church health
  //         </div>
  //       </div> */}

  //       <div className="cta-btn">
  //         <Link to={"/invite-team/" + id} target="_blank">
  //           <Button>INVITE MY BOARD MEMBERS</Button>
  //         </Link>
  //       </div>

  //     </div>
  //   }
  // };


  const showInvite = () => {
    if (isMember) {
      return null;
    } else {
      return <div className="invite-container-new">

        <h3>Invite your team members to see how they think as a team. Is the team aligned and working to grow giving?</h3>
        <p>It can be risky to get the team’s perspective. Discover where you have opportunities to better sync your giving culture and increase financial resources for your mission.</p>

        {/* <div className="invite-reasons">

          <div className="reason">
            <img src={analyzeIcon} alt="analyze" /><br />
            <div className="height30"></div>
            <span>Analyze</span><br />
            Discover what level of health your church is currently at
          </div>

          <div className="reason">
            <img src={alignmentIcon} alt="align" /><br />
            <div className="height30"></div>
            <span>Align</span><br />
            Align around a plan for increased effectiveness as a team
          </div>

          <div className="reason">
            <img src={advanceIcon} alt="advance" /><br />
            <div className="height30"></div>
            <span>Advance</span><br />
            Chart your course to move toward a greater level of church health
          </div>
        </div> */}

        <div className="cta-btn">
          <Link to={"/invite-team/" + id} target="_blank">
            <Button>INVITE YOUR TEAM</Button>
          </Link>
        </div>

      </div>
    }
  };

  const showTeamMember = () => {
    if (isMember) {
      return (
        <div className="team-container">
          <h3>Thank you for taking this assessment!</h3>
          Please get in touch with your leader for a next step.
          <br />
          <br />
        </div>
      );
    } else {
      return null;
    }
  };

  const getLeftMargin = () => {
    if (window.innerWidth < 800 && score > 95) {
      return { left: 90 + "%" };
    } else if (window.innerWidth < 800 && score < 5) {
      return { left: 10 + "%" };
    } else {
      return { left: score + "%" };
    }
  };

  const getBarTextLeft = (score) => {
    if (window.innerWidth < 650 && score > 80) {
      return { left: "-170px" }
    }
    return { left: "-100px" }
  }

  const showSchedule = () => {
    if (isMember) {
      return null;
    } else {
      return (
        <div className="schedule-container">
          <div className="avatar">
            <img src={scheduledImage} />
          </div>
          <h3>Schedule a call with Kim</h3>
          <p>Ready to optimize your board for fundraising or craft and execute an effective annual giving or capital campaign focused on Biblical generosity?</p>
				  <p>Talk with Kim to learn how your Christian school's fundraising can become truly transformative.</p>
          <div className="cta-btn">
            <a href="https://meetings.hubspot.com/kim-jennings" target="_blank">
              <Button>TALK TO KIM</Button>
            </a>
          </div>
        </div>
      );
    }
  };

  const showLegacyInvite = () => {
    if (isMember) {
      return null;
    } else {
      return (
        <div className="invite-legacy-container">
          <div className="invite-legacy">
            <p>Gain new insights into your team's alignment with your school board by inviting them to take this test!</p>
            <div className="cta-btn">
              <Link to={"/invite-team/" + id} target="_blank">
                <Button>INVITE MY BOARD</Button>
              </Link>
            </div>
          </div>
        </div>
      );
    }
  };

  if (isLoading) {
    return (
      <div className="container results loading">
        <CircularProgress style={{ color: "#007670" }} size={60} />
      </div>
    );
  }

  return (
    <>
      <div className="container results">
        <div className="details-container">

          {showTitleText()}

          <div className="progress-bar">
            <div className="si_ass_result">
              <div className="si_ass_progress">
                <div className="progress-line" style={{ left: score + '%' }}>
                  {/* <div className="progress-text" style={ windowWidth < 651 && score < 30 ? { left: '-50px', width: '10ch', top: '-85px' } : getBarTextLeft(score)}>{showProgressBarText(score)}</div> */}
                </div>
                <span className="msi_score" style={getLeftMargin()}>{score}%</span>
              </div>
              <div className="pervalue">
                <h4 className="nimp1" style={{ minWidth: '0%' }}></h4>
                {/* <h4 className="nimp" style={{ minWidth: '20%' }}></h4> */}
                <h4 className="mexpe" style={{ minWidth: '50%' }}></h4>
                {/* <h4 className="eexpe" style={{ minWidth: '20%' }}></h4> */}
                <h4 className="eexpe1" style={{ minWidth: '30%' }}></h4>
                <h4 className="eexpe2" style={{ minWidth: '20%' }}></h4>
                {/* <h4 className="vexpe" style={{ minWidth: '5%' }}></h4> */}
              </div>

              {/* <div className="pervalue">
                <h4 className="nimp1" style={{ minWidth: '0%' }}></h4>
                <h4 className="nimp" style={{ minWidth: '20%' }}></h4>
                <h4 className="mexpe" style={{ minWidth: '20%' }}></h4>
                <h4 className="eexpe" style={{ minWidth: '20%' }}></h4>
                <h4 className="eexpe1" style={{ minWidth: '20%' }}></h4>
                <h4 className="eexpe2" style={{ minWidth: '18%' }}></h4>
                <h4 className="vexpe" style={{ minWidth: '5%' }}></h4>
              </div> */}
              
              
              <div className="si_pro_header">
                <h4 className="nimp" style={{ minWidth: '50%' }}></h4>
                {/* <h4 className="mexpe" style={{ minWidth: '20%' }}></h4> */}
                {/* <h4 className="eexpe" style={{ minWidth: '20%' }}></h4> */}
                <h4 className="eexpe1" style={{ minWidth: '30%' }}></h4>
                {/* <h4 className="eexpe" style={{ minWidth: '30%' }}></h4> */}
              </div>
              
              {/* <div className="pervalue-text"> */}
                {/* <h4 className="nimp" style={{ minWidth: '45%',  }}></h4> */}
                {/* <h4 className="mexpe" style={{ minWidth: '25%',  }}></h4> */}
                {/* <h4 className="eexpe" style={{ minWidth: '30%', f }}></h4> */}
                {/* <h4 className="vexpe" style={{ minWidth: '5%' }}></h4> */}
              {/* </div> */}
             
              <div className="pervalue-text">
                {/* <p className="not-aligned" style={{ marginLeft: '0%', fontWeight: score <= 45 ? 800 : 600 }}>Not<br />Aligned</p> */}
                <p className="poor-aligned" style={{ width: '50%', fontWeight: score > 0 && score <= 50 ? 800 : 600 }}>Needs Attention</p>
                {/* <p className="some-aligned" style={{ marginLeft: '43%', fontWeight: score > 40 && score <= 60 ? 800 : 600 }}>Somewhat<br />Aligned</p> */}
                <p className="well-aligned" style={{ width: '30%', fontWeight: score > 50 && score <= 80 ? 800 : 600 }}>Adequate</p>
                <p className="high-aligned" style={{ width: '20%', fontWeight: score > 80 ? 800 : 600 }}>Excellent</p>
              </div>

            </div>
          </div>
          <div className="spacer"></div>
          <div className="spacer"></div>

          {/* <div className="score-section">
              <div className="score-txt">{subScore2}%</div>
              <div className="score-slider">
                <LinearProgress
                  className="custom-bar"
                  variant="determinate"
                  value={subScore2}
                />
              </div>
              LEADERS
            </div>

            <div className="score-section">
              <div className="score-txt">{subScore3}%</div>
              <div className="score-slider">
                <LinearProgress
                  className="custom-bar"
                  variant="determinate"
                  value={subScore3}
                />
              </div>
              STAFF
            </div> */}

          {showScoreText()}
          <div className="score-section-container">


            <div className="score-section">
              <p className="score-txt">{subScores[0]}%</p>
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScores[0]} /></div>
              <p className="score-name">GIVING</p>
            </div>
            {/* </div>
      </div> */}

            <div className="score-section">
              <p className="score-txt">{subScores[1]}%</p>
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScores[1]} /></div>
              <p className="score-name">MESSAGING</p>
            </div>

            <div className="score-section">
              <p className="score-txt">{subScores[2]}%</p>
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScores[2]} /></div>
              <p className="score-name">METRICS</p>
            </div>

            <div className="score-section">
              <p className="score-txt">{subScores[3]}%</p>
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScores[3]} /></div>
              <p className="score-name">LEADERSHIP</p>
            </div>

            <div className="score-section">
              <p className="score-txt">{subScores[4]}%</p>
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScores[4]} /></div>
              <p className="score-name">TEAM</p>
            </div>
          </div>
        </div>
      </div>

      {/* {showInvite()} */}

      {showTeamMember()}

      {/* <div className="testimonal-container">

        <div className="testimonial">

          <div className="testi">
            <span className="quotation-mark">“</span>Doing this assessment as a team gave us better insights into our level of alignment with our school board. It also helped us put a plan in place to make our meetings more productive, so that we continue to align on the board's objectives together.<span className="quotation-mark bottom"><div className="quotation-block">“</div></span>
          </div>

          <div className="avatar">
            <img src={testimonialImage} />
          </div>

          <div className="designation">
            Sam Davis<br />
            <span>Lead Pastor, Springs of Life Church</span>
          </div>

        </div>

      </div> */}

      {/* {showSchedule()} */}
      {/* {showLegacyInvite()} */}
      {!isMember && <Testimonial />}
      {/* </div> */}

    </>

  );
};
export default Results;
