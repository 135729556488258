import React from 'react';
import { Link, useLocation } from "react-router-dom";

// import Logo from '../../Assets/Images/logo.png';
import Logo from '../../Assets/Images/left-logo.svg';
import churchHealthLogo from '../../Assets/Images/logo-health.svg';
import snapshotMobileLogo from '../../Assets/Images/logo-snapshot-mobile.png';
import bleatLogo from '../../Assets/Images/logo-bleat.svg';
import bleatLogoMobile from '../../Assets/Images/logo-bleat-mobile.svg';
import useWindowDimensions from '../../Hooks/useWindowDimensions';

const Header = props => {

  const { windowWidth } = useWindowDimensions();
  const { pathname } = useLocation();

  console.log('pathname',pathname.split('/')[1])

  const getMaxWidthAccordingToPage = () => {
    if ( pathname == '/' ) {
      return '990px';
    }
    else if ( pathname.split("/")[1] == 'details') {
      return '1140px';
    }
    else if ( pathname.split("/")[1] == 'assessment' || pathname.split("/")[1] == 'results' || pathname.split("/")[1] == 'dashboard' ) {
      return '1230px';
    }
  }

  return (
    <>
      <header>
        <div className="header-wrapper" style={{ maxWidth: getMaxWidthAccordingToPage() }}>
        
          {windowWidth > 830 ? <div className="logo"><img src={Logo} alt="Logo" /></div> : <div className="logo"><img src={snapshotMobileLogo} alt="Logo" /></div>}
          
          <div className="assessment-title desktop" id="assessment-title">
            <p className="logo-text">Giving Culture</p>
            <p className="logo-subtext">ASSESSMENT</p>
          </div>

          { windowWidth > 830 ?
            <div className="bleat-logo"><img src={bleatLogo} alt="bleat logo" height="57px" width="50px" /></div> : <div className="bleat-logo"></div>
          }

        {/* {windowWidth <= 830 ?
          (<div className="bleat-logo-mobile">
            <img src={bleatLogoMobile} alt="bleat logo" height="43px" width="auto" />
          </div>) : (<div className="bleat-logo"><img src={bleatLogo} alt="bleat logo" height="57px" width="50px" /></div>)} */}

        </div>

      </header>
    </>
  )
}

export default Header;
